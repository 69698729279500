import { atadenAxiosInstance } from '../../common';
import { useCallback } from 'react';
import { useProtectedDelete, useProtectedGet, useProtectedPost } from '../../common/ProtectedRestCall.hook';
import { Card } from '../domain/Card';


type RestCard = {
  id: string;
  brand: string;
  last4: string;
  expYear: number;
  expMonth: number;
  createdDate: number;
  current: boolean;
};

const convertRestCardToDomain = (cards: RestCard[]): Card[] => {
  return cards.map((card) => {
    return {
      ...card,
    };
  });
};

export const useGetCards = (projectId: string) => {
  const protectedGet = useProtectedGet<RestCard[], Card[]>(atadenAxiosInstance, convertRestCardToDomain);

  return () => {
    return protectedGet('/api/v1/projects/' + projectId + '/payments/cards');
  };
};

export const useSelectCard = (projectId: string) => {
  const protectedPost = useProtectedPost<void, void>(atadenAxiosInstance, () => {
  });

  return (cardId: string): Promise<void> => {
    return protectedPost('/api/v1/projects/' + projectId + '/payments/cards/' + cardId + '/select', undefined);
  };
};

export const useDeleteCard = (projectId: string) => {
  const protectedDelete = useProtectedDelete(atadenAxiosInstance);

  return (cardId: string): Promise<void> => {
    return protectedDelete('/api/v1/projects/' + projectId + '/payments/cards/' + cardId);
  };
};


export const useCreateCardPaymentSession = (projectId: string) => {
  const protectedPost = useProtectedPost<string, string>(atadenAxiosInstance, (response) => response);

  return useCallback((): Promise<string> => {
    return protectedPost('/api/v1/projects/' + projectId + '/payments/card/sessions', undefined)
      .then((res: any) => res.secret);
  }, []);
};

var previousSessionId: string;

export const useValidateCardPaymentSession = (projectId: string) => {
  const protectedPost = useProtectedPost<void, void>(atadenAxiosInstance, () => {
  });

  return useCallback((sessionId: string): Promise<void> => {
    return new Promise<void>((success, failure) => {
      if (previousSessionId === sessionId) {
        success();
        return;
      }
      previousSessionId = sessionId;

      protectedPost('/api/v1/projects/' + projectId + '/payments/card/sessions/' + sessionId, undefined)
        .then(success)
        .catch(failure);
    });
  }, []);
};
