import { MenuItem } from './MenuItem';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { FeedbackModal } from '../../feedbacks/components/FeedbackModal';
import { pushToast } from '@ataden/design-system';

type FeedbackItemProps = {
  selected: boolean;
};

export const FeedbackItem = ({ selected }: FeedbackItemProps) => {

  const intl = useIntl();

  const [showModal, updateModalVisibility] = useState<boolean>(false);

  const onFeedback = () => {
    updateModalVisibility(true);
  };

  const onCancelFeedback = () => {
    updateModalVisibility(false);
  };

  const onFeedbackCreated = () => {
    updateModalVisibility(false);

    pushToast({
      title: intl.formatMessage({ id: 'user.feedback.notification.title' }),
      description: intl.formatMessage({ id: 'user.feedback.notification.content' }),
      type: 'SUCCESS',
    });
  };

  return (
        <>
            <MenuItem title={intl.formatMessage({ id: 'menu.feedback' })} selected={selected} onClick={onFeedback}/>

            {showModal && <FeedbackModal onCancel={onCancelFeedback} feedbackCreated={onFeedbackCreated}/>}
        </>
  );
};
