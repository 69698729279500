import { atadenAxiosInstance, RestPage } from '../../common';
import { useProtectedGet, useProtectedPost, useProtectedPut } from '../../common/ProtectedRestCall.hook';
import { BillInformation } from '../../billing/domain/BillInformation';
import { ProjectMember } from '../domain/ProjectMember';
import { ProjectInfo } from '../domain/ProjectInfo';
import {
  useGetCurrentProjectInfo,
  useGetProjectMembers,
  useInitCurrentProject,
  useInitProjectMembers,
} from '../ProjectStore';

type RestProjectMember = {
  userId: string;
  projectId: string;
  role: string;
  createdDate: number;
};

type RestProjectCreated = {
  id: string;
};

type RestProjectValidation = {
  paymentCardValidated: boolean;
  billInformationValidated: boolean;
};


type RestProjectInfo = {
  id: string;
  name: string;
  dns: string;
  status: 'CREATED' | 'READY';
  validation: RestProjectValidation | undefined;
};

export type RestAddress = {
  line1: string;
  line2: string;
  zipCode: string;
  city: string;
};

export type RestBillInformation = {
  name: string;
  address: RestAddress;
  tax: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
};

const convertRestProjectMemberToDomain = (projectMember: RestProjectMember): ProjectMember => {
  return {
    ...projectMember,
  };
};

const convertRestPageProjectMemberToDomain = (rest: RestPage<RestProjectMember>): ProjectMember[] => {
  return rest.items.map(convertRestProjectMemberToDomain);
};

const convertRestProjectInformationToDomain = (restBillInformation: RestBillInformation): BillInformation => {
  return {
    ...restBillInformation,
  };
};


export const useGetProjectInfo = () => {
  const protectedGet = useProtectedGet<RestProjectInfo, ProjectInfo>(atadenAxiosInstance, (restName) => {
    return { ...restName };
  });

  const getCurrentProjectInfo = useGetCurrentProjectInfo();

  return (projectId: string): Promise<ProjectInfo> => {
    return new Promise<ProjectInfo>((success, failure) => {

      const currentProjectInfo = getCurrentProjectInfo();
      if (currentProjectInfo?.id === projectId) {
        success(currentProjectInfo);
      }

      protectedGet('/api/v1/projects/' + projectId + '/info')
        .then(projectName => {
          success(projectName);
        })
        .catch(failure);
    });
  };
};

export const useSetCurrentProject = () => {
  const initCurrentProject = useInitCurrentProject();

  return (projectInfo: ProjectInfo | undefined) => {
    initCurrentProject(projectInfo);
  };
};

export const useGetUserProjectMembers = () => {
  const protectedGet = useProtectedGet<RestPage<RestProjectMember>, ProjectMember[]>(atadenAxiosInstance, convertRestPageProjectMemberToDomain);

  const initProjectMembers = useInitProjectMembers();
  const getProjectMembers = useGetProjectMembers();

  return (): Promise<ProjectMember[]> => {
    return new Promise<ProjectMember[]>((success, failure) => {

      const projectMembers = getProjectMembers();
      if (projectMembers.length > 0) {
        success(projectMembers);
        return;
      }

      protectedGet('/api/v1/projects/members')
        .then(members => {
          initProjectMembers(members);
          success(members);
        })
        .catch(failure);
    });
  };
};

export const useCreateProject = () => {
  const protectedPost = useProtectedPost<RestProjectCreated, string>(atadenAxiosInstance, (response) => response.id);

  return (projectName: string): Promise<string> => {
    return new Promise<string>((success, failure) => {

      protectedPost('/api/v1/projects', { dns: projectName })
        .then(projectId => {
          success(projectId);
        })
        .catch(failure);
    });
  };
};

export const useGetProjectBillInfo = () => {
  const protectedGet = useProtectedGet<RestBillInformation, BillInformation>(atadenAxiosInstance, convertRestProjectInformationToDomain);

  return (projectId: string): Promise<BillInformation> => {
    return new Promise<BillInformation>((success, failure) => {

      protectedGet('/api/v1/projects/' + projectId + '/bill-info')
        .then(billInformation => {
          success(billInformation);
        })
        .catch(failure);
    });
  };
};

export const usePutProjectBillInfo = () => {
  const protectedPut = useProtectedPut<void, void>(atadenAxiosInstance, () => {
  });

  return (projectId: string, billInformation: BillInformation): Promise<void> => {
    return new Promise<void>((success, failure) => {

      protectedPut('/api/v1/projects/' + projectId + '/bill-info', billInformation)
        .then(success)
        .catch(failure);
    });
  };
};
