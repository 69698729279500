import { useEffect } from 'react';
import { useValidateCardPaymentSession } from '../../repositories/PaymentRepository.hook';
import { getRouterSettingsPaymentMethodPath } from '../../../AuthenticatedRouter';
import { useNavigate } from 'react-router-dom';
import { useProjectEventBus } from '../../../projects/Project.hook';
import { ProjectPaymentUpdatedEvent } from '../../../projects/domain/ProjectEventBus';

type SyncStripePaymentMethodProps = {
  projectId: string;
  sessionId: string;
};

export const SyncStripePaymentMethod = ({ projectId, sessionId }: SyncStripePaymentMethodProps) => {
  const navigate = useNavigate();
  const validateCardPaymentSession = useValidateCardPaymentSession(projectId);
  const projectEventBus = useProjectEventBus();

  useEffect(() => {
    validateCardPaymentSession(sessionId)
      .then(() => {
        // TODO: understand why we have to wait 1s to get the new card.
        setTimeout(() => {
          projectEventBus.emit('onPaymentUpdated', { projectId: projectId } as ProjectPaymentUpdatedEvent);
          navigate(getRouterSettingsPaymentMethodPath(projectId), {
            replace: true,
          });
        }, 1000);
      }).catch(() => {
        // TODO: handle error
      });

  }, [sessionId]);

  return <></>;
};
