import { AtdnCopyButton, AtdnText, Colors } from '@ataden/design-system';
import styled from 'styled-components';

type ApiSectionCopyButton = {
  copyText: string;
  copiedText: string;
};

type ApiSectionInfoProps = {
  title: string;
  value: string;
  withCopyButton?: ApiSectionCopyButton;
};

const DataSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const DataTitle = styled(AtdnText)`

`;

const DataValue = styled(AtdnText)`
    margin-left: 4px;
`;

const CopyKeyButton = styled(AtdnCopyButton)`
    margin-left: 8px;
`;

export const ApiSectionInfo = ({ title, value, withCopyButton }: ApiSectionInfoProps) => {

  return (
        <DataSection>
            <DataTitle color={Colors.Brown700}>
                {title}:
            </DataTitle>
            <DataValue type="important"
                       color={Colors.Purple700}>{value}</DataValue>
            {withCopyButton && (
                <CopyKeyButton textToCopy={value}
                               tooltipToCopy={withCopyButton.copyText}
                               tooltipCopied={withCopyButton.copiedText}/>
            )}
        </DataSection>
  );
};
