import { FolderExplorationItem } from '../domain/FileExploration';
import { ExplorerAvailableFolderItem } from './ExplorerAvailableFolderItem';
import { ExplorerDeletedFolderItem } from './ExplorerDeletedFolderItem';


type ExplorerFolderItemProps = {
  bucketId: string;
  item: FolderExplorationItem;
  isSelected: boolean;

  onFolderClick: (path: string) => void;
  onSelectionChange: (selected: boolean) => void;
  onRecovered: () => void;
};

export const ExplorerFolderItem = ({
  bucketId,
  item,
  isSelected,
  onFolderClick,
  onSelectionChange,
  onRecovered,
}: ExplorerFolderItemProps) => {

  switch (item.status) {
    case 'AVAILABLE':
      return <ExplorerAvailableFolderItem item={item} isSelected={isSelected}
                                                onFolderClick={onFolderClick} onSelectionChange={onSelectionChange}/>;

    case 'DELETED':
    case 'FOLDER_DELETED':
    case 'BUCKET_DELETED':
      return <ExplorerDeletedFolderItem bucketId={bucketId} item={item} isSelected={isSelected}
                                              onFolderClick={onFolderClick} onSelectionChange={onSelectionChange}
                                              onRecovered={onRecovered}/>;
  }
};
