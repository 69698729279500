import { BucketDetails } from '../../domain/Bucket';
import { S3ConnectionPart } from './S3ConnectionPart';
import styled from 'styled-components';
import { ScreenSize } from '@ataden/design-system';
import { S3ClientPart } from './S3ClientPart';

type S3SectionProps = {
  bucket: BucketDetails;
};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: ${ScreenSize.desktop}px) {
        flex-direction: column;
        gap: 32px;
    }
`;

export const ApiSectionS3 = ({ bucket }: S3SectionProps) => {


  return (
        <Container>
            <S3ConnectionPart bucket={bucket}/>
            <S3ClientPart/>
        </Container>
  );
};
