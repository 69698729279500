import styled from 'styled-components';
import { AtdnText, Colors } from '@ataden/design-system';
import { OnboardingElement } from './OnboardingElement';
import { useNavigate } from 'react-router-dom';
import { getRouterSettingsBillingPath, getRouterSettingsPaymentMethodPath } from '../../AuthenticatedRouter';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useProjectEventBus } from '../../projects/Project.hook';
import { ProjectValidation } from '../../projects/domain/ProjectInfo';
import { useGetProjectInfo } from '../../projects/repositories/ProjectRepository.hook';

type OnboardingMenuPanelProps = {
  projectId: string;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;

    background-color: ${Colors.Purple700};
    padding: 32px 24px;
    border-radius: 12px;

    gap: 8px;
`;

const Title = styled(AtdnText)`
    color: ${Colors.White};

    margin-bottom: 16px;
`;


export const OnboardingMenuPanel = ({ projectId }: OnboardingMenuPanelProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const getProjectInfo = useGetProjectInfo();
  const projectEventBus = useProjectEventBus();

  const [projectValidation, updateProjectValidation] = useState<ProjectValidation>();

  const onPaymentClick = () => {
    navigate(getRouterSettingsPaymentMethodPath(projectId));
  };

  const onBillClick = () => {
    navigate(getRouterSettingsBillingPath(projectId));
  };

  useEffect(() => {
    const onBillInfoUpdated = () => {
      updateProjectValidation({
        ...projectValidation,
        billInformationValidated: true,
      } as ProjectValidation);
    };

    const onPaymentUpdated = () => {
      updateProjectValidation({
        ...projectValidation,
        paymentCardValidated: true,
      } as ProjectValidation);
    };

    projectEventBus.on('onBillInfoUpdated', onBillInfoUpdated);
    projectEventBus.on('onPaymentUpdated', onPaymentUpdated);

    return () => {
      projectEventBus.off('onBillInfoUpdated', onBillInfoUpdated);
      projectEventBus.off('onPaymentUpdated', onPaymentUpdated);
    };
  }, [projectEventBus]);


  useEffect(() => {
    getProjectInfo(projectId)
      .then((project) => {
        if (project.status !== 'CREATED') {
          updateProjectValidation(undefined);
          return;
        }

        updateProjectValidation(project.validation);
      });
  }, [projectId]);

  if (projectValidation === undefined) {
    return <></>;
  }

  return (
        <Container>
            <Title>
                <FormattedMessage id="menu.onboarding.title"/>
            </Title>
            <OnboardingElement label={intl.formatMessage({ id: 'menu.onboarding.task.create-project' })} finished={true}/>
            <OnboardingElement label={intl.formatMessage({ id: 'menu.onboarding.task.add-payment' })}
                               finished={projectValidation.paymentCardValidated}
                               onClick={onPaymentClick}/>
            <OnboardingElement label={intl.formatMessage({ id: 'menu.onboarding.task.add-bill-info' })}
                               finished={projectValidation.billInformationValidated}
                               onClick={onBillClick}/>
        </Container>
  );
};
