import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { getRouterBucketCreationPath } from '../../AuthenticatedRouter';
import { ServerExceptionReader } from '../../common/ServerException';
import { AtdnButton, AtdnInput, AtdnText, Colors, InputErrorText, Loader } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { BigPictureLayout } from '../../layout/BigPictureLayout';
import { Panel } from '../../common/Panel';
import {
  validateDnsNameCharacter,
  validateDnsNameHyphenPosition,
  validateDnsNameHyphenSequence,
  validateDnsNameLength,
} from '../../common/domain/DnsValidator';
import { useCreateProject } from '../repositories/ProjectRepository.hook';

const CustomPanel = styled(Panel)`
    max-width: 750px;
`;

const Title = styled(AtdnText)`
    text-align: left;
    margin-bottom: 24px;
`;

const Paragraph = styled(AtdnText)`
    text-align: left;
    margin-bottom: 48px;
`;

const Content = styled.div`

`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const PROJECT_NAME_LENGTH_ERROR: number = 1;
const PROJECT_NAME_CHAR_ERROR: number = 2;
const PROJECT_NAME_HYPHEN_POSITION_ERROR: number = 4;
const PROJECT_NAME_HYPHEN_SEQUENCE_ERROR: number = 8;
const PROJECT_NAME_ALREADY_EXIST_ERROR: number = 16;


export const CreateProjectPage = () => {

  const [projectName, updateProjectName] = useState<string>('');
  const [errors, updateErrors] = useState<number>(0);
  const [isCreatingProject, updateCreatingProject] = useState<boolean>(false);

  const intl = useIntl();
  const navigate = useNavigate();
  const createProject = useCreateProject();

  const onDnsUpdated = (inputName: string) => {
    const cleanedName = inputName.trim().toLowerCase();

    updateErrors((validateDnsNameLength(cleanedName) ? 0 : PROJECT_NAME_LENGTH_ERROR)
            + (validateDnsNameCharacter(cleanedName) ? 0 : PROJECT_NAME_CHAR_ERROR)
            + (validateDnsNameHyphenPosition(cleanedName) ? 0 : PROJECT_NAME_HYPHEN_POSITION_ERROR)
            + (validateDnsNameHyphenSequence(cleanedName) ? 0 : PROJECT_NAME_HYPHEN_SEQUENCE_ERROR));

    updateProjectName(inputName.toLowerCase());
  };

  const onCreate = useMemo(() => {
    return () => {
      updateCreatingProject(true);
      createProject(projectName.trim())
        .then((projectId) => {
          navigate(getRouterBucketCreationPath(projectId));
        })
        .catch(err => {
          if (ServerExceptionReader.isProjectNameAlreadyExistsException(err)) {
            updateErrors(PROJECT_NAME_ALREADY_EXIST_ERROR);
          }

          updateCreatingProject(false);
        });
    };
  }, [projectName, createProject, navigate]);

  const createButton = useMemo(() => {
    if (isCreatingProject) {
      return (<AtdnButton size='big' type="Success" disabled>
                <FormattedMessage id="onboarding.project.cta.creating"/>
                <Loader scale={2}/>
            </AtdnButton>);
    }

    return (
            <AtdnButton size='big' type="Success" onClick={onCreate} disabled={projectName.length === 0 || errors > 0}>
                <FormattedMessage id="onboarding.project.cta"/>
            </AtdnButton>);

  }, [isCreatingProject, projectName, onCreate]);

  return (
        <BigPictureLayout>
            <CustomPanel>
                <Title scale={7} color={Colors.Brown700}>
                    <FormattedMessage id="onboarding.project.title"/>
                </Title>

                <Content>
                    <Paragraph>
                        <FormattedMessage id="onboarding.project.description"/>
                    </Paragraph>
                    <InputWrapper>
                        <AtdnInput maxLength={200} label={intl.formatMessage({ id: 'onboarding.project.input' })}
                                   value={projectName} required
                                   onChange={(event) => onDnsUpdated(event.target.value)}/>

                        {(errors & PROJECT_NAME_LENGTH_ERROR) === PROJECT_NAME_LENGTH_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.length"/>
                            </InputErrorText>
                        )}
                        {(errors & PROJECT_NAME_CHAR_ERROR) === PROJECT_NAME_CHAR_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.characters"/>
                            </InputErrorText>
                        )}
                        {(errors & PROJECT_NAME_HYPHEN_POSITION_ERROR) === PROJECT_NAME_HYPHEN_POSITION_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.hyphens-position"/>
                            </InputErrorText>
                        )}
                        {(errors & PROJECT_NAME_HYPHEN_SEQUENCE_ERROR) === PROJECT_NAME_HYPHEN_SEQUENCE_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.hyphens-sequence"/>
                            </InputErrorText>
                        )}
                        {(errors & PROJECT_NAME_ALREADY_EXIST_ERROR) === PROJECT_NAME_ALREADY_EXIST_ERROR && (
                            <InputErrorText>
                                <FormattedMessage id="buckets.create.naming.input.error.conflict"/>
                            </InputErrorText>
                        )}
                    </InputWrapper>
                    <ButtonWrapper>
                        {createButton}
                    </ButtonWrapper>

                </Content>
            </CustomPanel>
        </BigPictureLayout>
  );
};

/**


 */
