import { AtdnButton, AtdnModal, AtdnText, Colors, spacing } from '@ataden/design-system';
import styled from 'styled-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDeleteCard } from '../../repositories/PaymentRepository.hook';
import { Card } from '../../domain/Card';

type CardDeletionModalProps = {
  projectId: string;
  card: Card;
  onDeleted: () => void;
  onClosed: () => void;
};


const Title = styled(AtdnText)`
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Label = styled(AtdnText)`
    color: ${Colors.Brown700};
    margin-bottom: 8px;
`;

const ActionContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${spacing(6)};
    gap: ${spacing(5)};
    width: 100%;
    justify-content: center;
`;


export const CardDeletionModal = ({ projectId, card, onDeleted, onClosed }: CardDeletionModalProps) => {
  const deleteCard = useDeleteCard(projectId);

  const onValidationRequested = () => {
    deleteCard(card.id)
      .then(onDeleted)
      .catch(() => {
        // TODO: manage error
      });
  };

  return (
        <AtdnModal onCancel={onClosed}>
            <Title scale={5} type="important">
                <FormattedMessage id="billing.payment-method.deletion.modal.title"/>
            </Title>
            <Label scale={3}>
                <FormattedMessage id="billing.payment-method.deletion.modal.label"
                                  values={{ card: '\'XXXX XXXXX XXXX ' + card.last4 + '\'' }}/>
            </Label>

            <ActionContainer>
                <AtdnButton onClick={onValidationRequested}>
                    <FormattedMessage id="billing.payment-method.deletion.modal.validate"/>
                </AtdnButton>
                <AtdnButton onClick={onClosed}>
                    <FormattedMessage id="billing.payment-method.deletion.modal.cancel"/>
                </AtdnButton>
            </ActionContainer>
        </AtdnModal>
  );
};
