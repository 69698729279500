import styled from 'styled-components';
import { AtdnText, Colors } from '@ataden/design-system';
import { useIntl } from 'react-intl';

type S3ClientItemProps = {
  iconUrl: string;
  name: string;
  documentationUrl: string;
};

const Container = styled.div`
    display: grid;
    grid-template-columns: 40px minmax(0px, 1fr) 50px;

    align-items: center;
    gap: 8px;
`;

const AppName = styled(AtdnText)`
    padding-right: 32px;
`;

const IconWrapper = styled.img`
    width: 32px;
    height: 32px;
`;

export const S3ClientItem = ({ iconUrl, name, documentationUrl }: S3ClientItemProps) => {

  const intl = useIntl();

  return (
        <Container>
            <IconWrapper src={iconUrl}/>
            <a href={documentationUrl} target="_blank" rel="noreferrer">
                <AppName type="important"
                         color={Colors.Purple700}>{intl.formatMessage({ id: 'bucket.api.s3.clients.guide' }, { app: name })}</AppName>
            </a>

        </Container>
  );
};
