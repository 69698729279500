import { FormattedMessage } from 'react-intl';
import { AtdnText, Colors, ScreenSize } from '@ataden/design-system';
import styled from 'styled-components';
import { S3ClientItem } from './S3ClientItem';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled(AtdnText)`
    margin-bottom: 36px;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
`;

const ClientContainer = styled.div`
    display: flex;
    flex-direction: column;

    width: fit-content;
    gap: 16px;
    padding-top: 32px;

    @media (max-width: ${ScreenSize.desktop}px) {
        padding-top: 0;
    }
`;

export const S3ClientPart = () => {

  return (
        <Container>
            <Title scale={6} color={Colors.Brown700}>
                <FormattedMessage id="bucket.api.s3.clients.title"/>
            </Title>
            <Description>
                <AtdnText>
                    <FormattedMessage id="bucket.api.s3.clients.description"/>
                </AtdnText>
            </Description>
            <ClientContainer>
                <S3ClientItem name="FileZilla Pro"
                              iconUrl="/img/FileZilla.png"
                              documentationUrl="https://ataden.com/blog/s3-filezilla"/>
                <S3ClientItem name="WinSCP"
                              iconUrl="/img/WinSCP.png"
                              documentationUrl="https://ataden.com/blog/winscp-avec-le-protocole-s3-chez-ataden"/>
                <S3ClientItem name="CyberDuck"
                              iconUrl="/img/CyberDuck.png"
                              documentationUrl="https://ataden.com/blog/s3-cyberduck"/>
            </ClientContainer>
        </Container>
  );
};
