import React, { useEffect, useState } from 'react';
import { BillInformation, EMPTY_BILL_INFORMATION } from '../../domain/BillInformation';
import { BillInformationEditorComponent } from './BillInformationEditorComponent';
import { BillInformationDisplayComponent } from './BillInformationDisplayComponent';
import { AtdnButton, Loader } from '@ataden/design-system';
import { FormattedMessage } from 'react-intl';
import { useGetProjectBillInfo } from '../../../projects/repositories/ProjectRepository.hook';

type BillInformationComponentProps = {
  projectId: string;
};


export const BillInformationComponent = ({ projectId }: BillInformationComponentProps) => {

  const getProjectBillInfo = useGetProjectBillInfo();

  const [currentInformation, updateCurrentInformation] = useState<BillInformation | undefined>();
  const [isLoading, updateLoading] = useState<boolean>(false);
  const [informationEdition, updateInformationEdition] = useState<boolean>(false);

  useEffect(() => {
    updateLoading(true);
    getProjectBillInfo(projectId)
      .then((info) => {
        updateCurrentInformation(info);
      })
      .catch(reason => {
        if (reason?.response?.status === 404) {

        }
      })
      .finally(() => {
        updateLoading(false);
      });
  }, [projectId]);

  const onInformationEdited = (updatedInformation: BillInformation) => {
    updateCurrentInformation(updatedInformation);
    updateInformationEdition(false);
  };

  const onInformationToUpdate = () => {
    updateInformationEdition(true);

    if (!currentInformation) {
      updateCurrentInformation(EMPTY_BILL_INFORMATION);
    }
  };

  if (isLoading) {
    return <Loader scale={5}/>;
  }

  if (!currentInformation) {
    return (
            <AtdnButton onClick={onInformationToUpdate} size='big'>
                <FormattedMessage id="project.info.edit.cta"/>
            </AtdnButton>
    );
  }

  return (
        <>

            {informationEdition ?
                <BillInformationEditorComponent projectId={projectId} initialInformation={currentInformation}
                                                informationUpdated={onInformationEdited}/>
              :
                <BillInformationDisplayComponent information={currentInformation}
                                                 onEditRequest={onInformationToUpdate}/>
            }
        </>
  );
};

