import styled from 'styled-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getRouterHomePath, RoutePath } from '../../AuthenticatedRouter';
import { Colors, Loader } from '@ataden/design-system';
import { useGetCurrentUser } from '../../users/repositories/UserRepository.hook';
import { useGetUserProjectMembers } from '../../projects/repositories/ProjectRepository.hook';
import { ProjectMember } from '../../projects/domain/ProjectMember';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
`;

const LoaderContainer = styled.div`
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
`;

export const DashboardLoaderPage = () => {
  const getUserProjectMembers = useGetUserProjectMembers();
  const getCurrentUser = useGetCurrentUser();
  const navigate = useNavigate();

  const onLicenseNotApproved = () => {
    navigate(RoutePath.userLicenseApprobation);
  };

  const onNoProjectFound = () => {
    navigate(RoutePath.newProject);
  };

  const onProjectFound = (projectMember: ProjectMember[]) => {
    navigate(getRouterHomePath(projectMember[0].projectId));
  };

  const loadUserProjectMembers = () => {
    getUserProjectMembers()
      .then((projectMember) => {
        if (projectMember.length > 0) {
          onProjectFound(projectMember);
        } else {
          onNoProjectFound();
        }
      })
      .catch((err) => {
        console.log('err', err);
        // TODO: handle loading error
      });
  };

  getCurrentUser()
    .then(user => {
      if (!user.license) {
        onLicenseNotApproved();
      } else {
        loadUserProjectMembers();
      }
    })
    .catch(() => {
      // TODO: handle loading error
    });


  return (
        <Container>
            <LoaderContainer>
                <Loader scale={6} secondaryColor={Colors.Brown700}/>
            </LoaderContainer>
        </Container>
  );
};
