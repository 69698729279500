import styled from 'styled-components';
import { ProjectSelector } from '../../projects/components/ProjectSelector';
import { Colors, spacing } from '@ataden/design-system';


type BannerNavigationMenuProps = {};

const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 100%;
    background-color: ${Colors.White};
    padding: ${spacing(5)} ${spacing(5)} ${spacing(5)} ${spacing(6)};

    border-bottom: 1px solid ${Colors.Gray100};
`;

const MenuItems = styled.div`
    padding: 0 ${spacing(4)} 0 ${spacing(6)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;


export const BannerNavigationMenu = ({}: BannerNavigationMenuProps) => {

  return (
        <Container>
            <MenuItems>
                <ProjectSelector/>
            </MenuItems>
        </Container>
  );
};
