import { createContext, ReactElement, useMemo } from 'react';
import { ActionCreatorWithPayload, configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'redux';
import { projectStoreSlice } from './ProjectStore';
import { eventbus, EventBus } from '@ataden/event-bus';
import { ProjectInfo } from './domain/ProjectInfo';
import { ProjectMember } from './domain/ProjectMember';
import { ProjectEventBusType } from './domain/ProjectEventBus';

type ProjectContextProviderProps = {
  children: ReactElement;
};

type ProjectConfig = {
  store: Store;

  initProjectMemberAction: ActionCreatorWithPayload<ProjectMember[]>;
  initCurrentProjectAction: ActionCreatorWithPayload<ProjectInfo | undefined>;

  projectEventBus: EventBus<ProjectEventBusType>;
} & ProjectContextProviderProps;


export const ProjectContext = createContext<Partial<ProjectConfig>>({});

const Provider: React.FC<ProjectConfig> = (config: ProjectConfig) => {
  return <ProjectContext.Provider value={config}>{config.children}</ProjectContext.Provider>;
};

export const ProjectContextProvider = ({ children }: ProjectContextProviderProps) => {
  const store: Store = useMemo(() => configureStore({
    reducer: projectStoreSlice.reducer,
  }), []);

  const projectEventBus = useMemo(() => {
    return eventbus<ProjectEventBusType>();
  }, []);

  const {
    initProjectMember,
    initCurrentProject,
  } = projectStoreSlice.actions;

  return (
        <Provider
            store={store}
            initProjectMemberAction={initProjectMember}
            initCurrentProjectAction={initCurrentProject}
            projectEventBus={projectEventBus}
        >
            {children}
        </Provider>
  );
};
