import { PageDescription, PageHeader, PageLayout } from '../../layout/PageLayout';
import { useIntl } from 'react-intl';
import { PaymentMethodComponent } from './components/PaymentMethodComponent';
import React from 'react';

export const PaymentMethodsPage = () => {
  const intl = useIntl();

  return (
        <PageLayout>
            <PageHeader>
                <PageDescription>
                    <div
                        dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'billing.payment-method.description' }) }}/>
                </PageDescription>
            </PageHeader>

            <PaymentMethodComponent/>
        </PageLayout>

  );
};
