import { useProtectedPost } from '../../common/ProtectedRestCall.hook';
import { atadenAxiosInstance } from '../../common';

export const usePostFeedbackRepository = () => {
  const protectedPost = useProtectedPost<void, void>(atadenAxiosInstance, () => {
  });

  return (content: string): Promise<void> => {
    return new Promise<void>((success, failure) => {
      protectedPost('/api/v1/supports/messages', {
        content: content,
      })
        .then(success)
        .catch(failure);
    });
  };
};
