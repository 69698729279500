import { AtdnButton, AtdnModal, AtdnTextArea, Colors, Loader } from '@ataden/design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import styled from 'styled-components';
import { usePostFeedbackRepository } from '../repository/FeedbackRepository';

type FeedbackModalProps = {
  feedbackCreated: () => void;
  onCancel: () => void;
};

const Title = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 90%;
    text-transform: uppercase;
    color: ${Colors.Brown700};
    white-space: break-spaces;
    margin-bottom: 24px;
`;

const Label = styled.div`
    color: ${Colors.Brown700};
    margin-bottom: 32px;
    word-break: break-word;
`;

const TextArea = styled(AtdnTextArea)`
    margin-bottom: 48px;
`;

const BtnLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 32px;
`;

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 400px;
`;

export const FeedbackModal = ({ feedbackCreated, onCancel }: FeedbackModalProps) => {

  const intl = useIntl();
  const [currentFeedback, updateCurrentFeedback] = useState<string>('');
  const [sending, updateSending] = useState<boolean>(false);

  const postFeedbackRepository = usePostFeedbackRepository();

  const onSendFeedback = () => {
    updateSending(true);

    postFeedbackRepository(currentFeedback)
      .then(feedbackCreated)
      .finally(() => updateSending(false));
  };

  return (
        <AtdnModal onCancel={onCancel}>
            <ModalContent>
                <Title>
                    <FormattedMessage id="user.feedback.title"/>
                </Title>
                <Label>
                    <FormattedMessage id="user.feedback.content"/>:
                </Label>
                <TextArea label={intl.formatMessage({ id: 'user.feedback.input' })} value={currentFeedback}
                          onChange={(evt) => updateCurrentFeedback(evt.target.value)}
                          maxLength={499}
                />
                <BtnLine>
                    <AtdnButton onClick={onCancel} type="Primary" size="big">
                        <FormattedMessage id="user.feedback.cancel"/>
                    </AtdnButton>
                    <AtdnButton onClick={onSendFeedback}
                                disabled={currentFeedback.trim().length === 0 || sending}
                                type="Success"
                                size="big">
                        <FormattedMessage id="user.feedback.send"/>
                        {sending && <Loader scale={1}/>}
                    </AtdnButton>
                </BtnLine>
            </ModalContent>
        </AtdnModal>
  );
};
