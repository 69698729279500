import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Store } from 'redux';
import { ProjectMember } from './domain/ProjectMember';
import { ProjectInfo } from './domain/ProjectInfo';
import { ProjectContext } from './ProjectProvider';


export type ProjectState = Readonly<{
  projectMember?: ProjectMember[],
  currentProject?: ProjectInfo
}>;

const initialState: ProjectState = { projectMember: [], currentProject: undefined };


export const projectStoreSlice = createSlice({
  name: 'projects',
  initialState: initialState,
  reducers: {
    initProjectMember(state, action: PayloadAction<ProjectMember[]>) {
      state.projectMember = action.payload;
    },
    initCurrentProject(state, action: PayloadAction<ProjectInfo | undefined>) {
      state.currentProject = action.payload;
    },
  },
});

export const useProjectStore = (): Store => {
  return useContext(ProjectContext).store!;
};


export const useGetProjectMembers = () => {
  const store = useProjectStore();

  return (): ProjectMember[] => {
    return store.getState().projectMember;
  };
};


export const useInitProjectMembers = () => {
  const store = useProjectStore();
  const initProjectMember = useContext(ProjectContext).initProjectMemberAction!;

  return (projectMembers: ProjectMember[]) => {
    store.dispatch(initProjectMember(projectMembers));
  };
};


export const useGetCurrentProjectInfo = () => {
  const store = useProjectStore();

  return (): ProjectInfo | undefined => {
    return store.getState().currentProject;
  };
};


export const useInitCurrentProject = () => {
  const store = useProjectStore();
  const initProject = useContext(ProjectContext).initCurrentProjectAction!;

  return (project: ProjectInfo | undefined) => {
    store.dispatch(initProject(project));
  };
};
