import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRouterSettingsApiKeysPath, useGetProjectIdFromPath } from '../../../AuthenticatedRouter';
import { useGetCurrentProjectInfo } from '../../../projects/ProjectStore';
import { AtdnText, Colors } from '@ataden/design-system';
import { ApiSectionInfo } from './ApiSectionInfo';
import { BucketDetails } from '../../domain/Bucket';
import styled from 'styled-components';

type S3ConnectionPartProps = {
  bucket: BucketDetails;
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Title = styled(AtdnText)`
    margin-bottom: 36px;
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 48px;
`;

export const S3ConnectionPart = ({ bucket }: S3ConnectionPartProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const projectId = useGetProjectIdFromPath();

  const getCurrentProjectInfo = useGetCurrentProjectInfo();

  const endpointUrl = `${getCurrentProjectInfo()?.dns}.${process.env.REACT_APP__S3_DOMAIN}`;
  const pathUrl = `/${bucket.dns}`;

  const onApiKeyPage = () => {
    navigate(getRouterSettingsApiKeysPath(projectId));
  };

  return (
        <Container>
            <Title scale={6} color={Colors.Brown700}>
                <FormattedMessage id="bucket.api.s3.title"/>
            </Title>
            <Description>
                <AtdnText>
                    <FormattedMessage id="bucket.api.s3.description"/>
                </AtdnText>
                <AtdnText>
                    <a
                        href={getRouterSettingsApiKeysPath(projectId)} onClick={onApiKeyPage}><FormattedMessage
                        id="bucket.api.s3.api-key"/>
                    </a>
                </AtdnText>
            </Description>

            <InfoContainer>
                <ApiSectionInfo title={intl.formatMessage({ id: 'bucket.api.s3.endpoint.title' })}
                                value={endpointUrl}
                                withCopyButton={{
                                  copyText: intl.formatMessage({ id: 'bucket.api.s3.endpoint.copy' }),
                                  copiedText: intl.formatMessage({ id: 'bucket.api.s3.endpoint.copied' }),
                                }}/>
                <ApiSectionInfo title={intl.formatMessage({ id: 'bucket.api.s3.path.title' })}
                                value={pathUrl}
                                withCopyButton={{
                                  copyText: intl.formatMessage({ id: 'bucket.api.s3.path.copy' }),
                                  copiedText: intl.formatMessage({ id: 'bucket.api.s3.path.copied' }),
                                }}/>
                <ApiSectionInfo title={intl.formatMessage({ id: 'bucket.api.s3.port.title' })} value={'443'}/>
                <ApiSectionInfo title={intl.formatMessage({ id: 'bucket.api.s3.signature.title' })} value={'v4'}/>

            </InfoContainer>
        </Container>
  );
};
