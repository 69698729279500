import { useEffect, useState } from 'react';
import { useGetProjectIdFromPath } from '../../AuthenticatedRouter';
import { SelectedProjectTitle } from './SelectedProjectTitle';
import { useGetProjectInfo, useSetCurrentProject } from '../repositories/ProjectRepository.hook';

export const ProjectSelector = () => {
  const projectId = useGetProjectIdFromPath();

  const getProjectInfo = useGetProjectInfo();
  const setCurrentProject = useSetCurrentProject();

  const [currentProjectName, updateCurrentProjectName] = useState<string>();

  useEffect(() => {
    getProjectInfo(projectId)
      .then(info => {
        setCurrentProject(info);
        updateCurrentProjectName(info.name);
      })
      .catch(() => {
        // TODO: handle error
      },
      );
  }, []);

  if (!currentProjectName) {
    return <></>;
  }

  return (
        <>
            <SelectedProjectTitle title={currentProjectName}/>
        </>
  );
};
