import { useProtectedDelete, useProtectedGet, useProtectedPost } from '../../common/ProtectedRestCall.hook';
import { atadenAxiosInstance } from '../../common';
import { ApiKey } from '../domain/ApiKey';
import { ApiKeyCreated } from '../domain/ApiKeyCreated';

type RestApiKey = {
  accessId: string;
  createdDate: number;
};

type RestApiKeyCreated = {
  accessId: string;
  secretKey: string;
};

export const useGetApiKeys = () => {
  const protectedGet = useProtectedGet<RestApiKey[], ApiKey[]>(atadenAxiosInstance, (restApiKey) => {
    return [...restApiKey];
  });

  return (projectId: string): Promise<ApiKey[]> => {
    return new Promise<ApiKey[]>((success, failure) => {

      protectedGet('/api/v1/projects/' + projectId + '/keys')
        .then(apiKey => {
          success(apiKey);
        })
        .catch(failure);
    });
  };
};


export const useCreateApiKey = () => {
  const protectedPost = useProtectedPost<RestApiKeyCreated, ApiKeyCreated>(atadenAxiosInstance, (restApiKeyCreated) => {
    return { ...restApiKeyCreated };
  });

  return (projectId: string): Promise<ApiKeyCreated> => {
    return new Promise<ApiKeyCreated>((success, failure) => {

      protectedPost('/api/v1/projects/' + projectId + '/keys', undefined)
        .then(apiKeyCreated => {
          success(apiKeyCreated);
        })
        .catch(failure);
    });
  };
};


export const useRevokeApiKey = () => {
  const protectedDelete = useProtectedDelete(atadenAxiosInstance);

  return (projectId: string, accessId: string): Promise<void> => {
    return new Promise((success, failure) => {

      protectedDelete('/api/v1/projects/' + projectId + '/keys/' + accessId)
        .then(success)
        .catch(failure);
    });
  };
};
