import { Colors, CreditCardIcon } from '@ataden/design-system';
import styled from 'styled-components';
import React, { useState } from 'react';
import { Card } from '../../domain/Card';
import { CardDefaultSelectionModal } from './CardDefaultSelectionModal';

type CardDefaultSelectionProps = {
  projectId: string;
  card: Card;
  onSelection: () => void;
};


const StarIconWrapper = styled.div`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

export const CardDefaultSelection = ({
  projectId,
  card,
  onSelection,
}: CardDefaultSelectionProps) => {

  const [showSelectionModal, updateSelectionModal] = useState<boolean>(false);

  const onCardSelection = () => {
    updateSelectionModal(false);
    onSelection();
  };

  return (
        <>
            <StarIconWrapper onClick={() => updateSelectionModal(true)}>
                <CreditCardIcon color={card.current ? Colors.Purple500 : Colors.White} borderColor={Colors.Brown700}/>
            </StarIconWrapper>

            {showSelectionModal && (
                <CardDefaultSelectionModal card={card} projectId={projectId} onSelection={onCardSelection}
                                           onClosed={() => updateSelectionModal(false)}/>
            )}

        </>
  );
};
